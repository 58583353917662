import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

// For Dev
// export const firebaseConfig = {
//     apiKey: "AIzaSyDXexopwC33idzHOUlhLoS-Vqx5v1EbpwU",
//     authDomain: "jivebird-web.firebaseapp.com",
//     projectId: "jivebird-web",
//     storageBucket: "jivebird-web.appspot.com",
//     messagingSenderId: "810252508079",
//     appId: "1:810252508079:web:a6f7a92e420bded5096d5a",
//     measurementId: "G-R9K7ZB3LS4"
// };

// For Prod
export const firebaseConfig = {
  apiKey: 'AIzaSyB3uc-D8IfQ368Gmhm7XFMdcprL6ssnpBk',
  authDomain: 'jivebird-977d2.firebaseapp.com',
  databaseURL: 'https://jivebird-977d2.firebaseio.com',
  projectId: 'jivebird-977d2',
  storageBucket: 'jivebird-977d2.appspot.com',
  messagingSenderId: '1049862410212',
  appId: '1:1049862410212:web:752b8342eac0abfeb1c1f1',
  measurementId: 'G-CPSSC4YZP6',
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAnalytics = getAnalytics();
