import React, { useEffect } from 'react';
import './index.css';
import { Route, Switch } from 'react-router-dom';
import { shape } from 'prop-types';
import Loader from '@components/Loader';
import Loadable from 'react-loadable';
import { firebaseAnalytics } from '../firebaseConfig';
import { logEvent } from 'firebase/analytics';

const errorLoading = (err) =>
  console.log('Dynamic home page loading failed', err);

const CreateJiveRoute = Loadable({
  loader: () => import('@routes/CreateJiveBird').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const CongratsRoute = Loadable({
  loader: () => import('@components/congrats/congrats').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const RegisterRoute = Loadable({
  loader: () => import('@routes/NewUser').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const Routes = (props) => {
  useEffect(() => {
    logEvent(firebaseAnalytics, 'EventAppOpen');
  }, []);

  return (
    <Switch>
      <Route key="createjive" exact path="/" component={CreateJiveRoute} />
      <Route key="referral" exact path="/referral" component={CongratsRoute} />
      <Route key="register" exact path="/register" component={RegisterRoute} />
      <Route key="register" exact path="/login" component={RegisterRoute} />
    </Switch>
  );
};

Routes.propTypes = {
  history: shape({}).isRequired,
  location: shape({}).isRequired,
  match: shape({}).isRequired,
};

const RootRoutes = (props) => <Route {...props} component={Routes} />;

export default RootRoutes;
